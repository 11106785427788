import React from 'react';
import { AssetV2 } from 'types/asset.type';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';

interface Props {
    asset: AssetV2<unknown, unknown>;
    loading?: boolean;
    onSetReleaseDialogOpen: () => void;
}

const AssetManagementReleaseIconButton = ({ asset, loading, onSetReleaseDialogOpen }: Props) => {
    const { languageNameSpace, userHasRight } = useAssetManagementConfigContext();
    const isReleased = asset.version === asset.releasedVersion;

    if (isReleased) return null;
    if (asset.status !== 'available') return null;
    if (!userHasRight('management')) return null;

    return (
        <Tooltip title={Translation.get('dialogs.release.confirmBtn', languageNameSpace)}>
            <IconButton color="success" size="small" loading={loading} onClick={onSetReleaseDialogOpen}>
                <Icon fontSize="small">published_with_changes</Icon>
            </IconButton>
        </Tooltip>
    );
};

export default AssetManagementReleaseIconButton;
